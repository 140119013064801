<template lang="pug">
	v-container(fluid class="grey lighten-4")
		v-layout(wrap row)
			v-flex(xs12 md9 class="white mb-5")
				div(class="viewer" ref="viewer")
				v-container(class="description" v-if="tour")
					h1
						| {{tour.name}}

						span(class="ml-3")
							v-icon(class="mr-1" color="indigo") mdi-eye
							| {{tour.views}}
						span(class="ml-3")
							v-btn(:loading="likeTourLoading" flat icon @click="likeTour")
								v-icon(:color="isTourLiked ? 'red' : 'default'") mdi-heart
							| {{tour.likes}}

					p {{tour.description}}

			v-flex(xs12 md3)
				v-container
					h3(class="mb-3") Más Tours
					v-divider
					div(v-if="!loading")
						tour(
							v-for="(t, i) in toursRelated"
							:key="i"
							:tour="t"
							class="mb-2"
							@click="goToTour(t)"
						)
					div(v-else)
						tour(v-for="t in [1,2,3]" :key="t" loading class="mb-2")

</template>

<script>
import 'tour-renderer';
import store from 'store';
import {queries, api} from '@/modules/tours/services';
import {transformTourDataForViewer} from '@/modules/common/helpers';
import Tour from '@/modules/tours/components/Tour';
import { setTimeout, clearTimeout } from 'timers';

const TourRenderer = window.TourRenderer;
const VIEW_TOUR_TIMEOUT_SEC = 10;
const MILI_SECS = 1000;

export default {
	name: 'ViewTour',
	components: {Tour},
	data() {
		return {
			tour: null,
			toursRelated: [],
			loading: false,
			viewTourTimeout: null,
			isTourLiked: false,
			likeTourLoading: false
		}
	},

	methods: {
		initViewTimeout(id) {
			this.viewTourTimeout = setTimeout(() => {
				api.viewTour(id);
				this.tour.views += 1;
			}, VIEW_TOUR_TIMEOUT_SEC * MILI_SECS);
		},

		destroyViewTimeout() {
			if(this.viewTourTimeout) {
				clearTimeout(this.viewTourTimeout);
			}
		},

		initializedTour() {
			if(this.viewer) {
				this.viewer.destroy();
			}

			this.viewer = new TourRenderer(transformTourDataForViewer(this.tour), this.viewerDOM, {
				autoLoad: true,
			});
		},

		goToTour(tour) {
			this.$router.push({name: 'viewTour', params: {id: tour.id}});
		},

		likeTour() {
			if(this.isTourLiked) return;

			this.isTourLiked = true;
			const id = this.tour.id;

			const request = api.likeTour(this.tour.id);

			request.then(res => {
				this.tour.likes = res.data.likes;
				store.set(`tour-${id}.liked`, this.isTourLiked);
			});

			const setLoadingFalse = () => (this.likeTourLoading = false)
			this.likeTourLoading = true;
			request.then(setLoadingFalse).catch(setLoadingFalse);
		},

		checkIsTourLike() {
			const id = this.tour.id;
			this.isTourLiked = store.get(`tour-${id}.liked`);
		},

		viewTour(id) {
			if(!store.get('tour.viewed')) {
				this.destroyViewTimeout();
				this.initViewTimeout(id);
			}


			this.loading = true;

			queries.getTour(id).then(ref => {
				this.tour = ref.data();
				this.tour.id = id;

				this.checkIsTourLike();
				this.initializedTour();

				this.getRelatedTours();
			});


		},

		getRelatedTours() {
			this.toursRelated = [];
			let request;

			if(this.tour.category) {
				request = queries.getToursByCategory({category: this.tour.category});

			} else {
				request = queries.getRandomTours({limit:4});
			}

			return request.then(tours => {
				this.toursRelated.push(...tours.filter(tour => tour.id !== this.tour.id));
				this.loading = false;
			});
		}
	},
	beforeRouteUpdate(to, from, next) {
		this.viewTour(to.params.id);
		next();
	},

	beforeRouteEnter(to, from, next) {
		next(vm => {
			vm.viewTour(to.params.id)
		})
	},

	computed: {
		viewerDOM() {
			return this.$refs.viewer;
		}
	}
}
</script>

<style lang="less" scoped>
	.viewer {
		height: 500px;
	}

	.description {
		min-height: 100px;
	}
</style>
