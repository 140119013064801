import { render, staticRenderFns } from "./ViewTour.view.vue?vue&type=template&id=196f8f46&scoped=true&lang=pug&"
import script from "./ViewTour.view.vue?vue&type=script&lang=js&"
export * from "./ViewTour.view.vue?vue&type=script&lang=js&"
import style0 from "./ViewTour.view.vue?vue&type=style&index=0&id=196f8f46&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "196f8f46",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VBtn,VContainer,VDivider,VFlex,VIcon,VLayout})
